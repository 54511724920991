import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';

import css from './SmsVerificationForm.module.css';

export const VarificationStatus = {
  LOADING: 'loading',
  SENT: 'sent',
  FAILED: 'failed',
  SEND_CODE_ERROR: 'sendCodeError',
};

const SmsVerificationFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        intl,
        statusText,
        resendCode,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;

      const verificationCodeRequired = validators.required(
        intl.formatMessage({
          id: 'SmsVerificationForm.verificationCodeRequired',
        })
      );

      const verificationCodeCheck = validators.verificationCode(
        intl.formatMessage({
          id: 'SmsVerificationForm.verificationCodeCheck',
        })
      );

      const verificationCodeValidator = validators.composeValidators(
        verificationCodeRequired,
        verificationCodeCheck
      );

      const verificationCodeLabel = intl.formatMessage({
        id: 'SmsVerificationForm.verificationCodeInputLabel',
      });

      const verificationCodePlaceholder = intl.formatMessage({
        id: 'SmsVerificationForm.verificationCodeInputPlaceholder',
      });

      const status = (
        <div>
          {statusText === VarificationStatus.LOADING && (
            <p className={css.loading}>
              <FormattedMessage id="SmsVerificationForm.loading" />
            </p>
          )}
          {statusText === VarificationStatus.SENT && (
            <p className={css.sentVerification}>
              <FormattedMessage id="SmsVerificationForm.verificationSent" />
            </p>
          )}
          {statusText === VarificationStatus.FAILED && (
            <p className={css.wrongVerification}>
              <FormattedMessage id="SmsVerificationForm.wrongVerificationCode" />
              {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={resendCode}>resend code</a>
            </p>
          )}
          {statusText === VarificationStatus.SEND_CODE_ERROR && (
            <p className={css.wrongVerification}>
              <FormattedMessage id="SmsVerificationForm.sendCodeFailed" />
            </p>
          )}
        </div>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="text"
              id="code"
              name="code"
              label={verificationCodeLabel}
              placeholder={verificationCodePlaceholder}
              validate={verificationCodeValidator}
            />
          </div>
          {status}
          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress}>
              <FormattedMessage id="SmsVerificationForm.verify" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SmsVerificationFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SmsVerificationFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SmsVerificationForm = compose(injectIntl)(SmsVerificationFormComponent);
SmsVerificationForm.displayName = 'SmsVerificationForm';

export default SmsVerificationForm;
